import { Injectable } from '@angular/core';
import { WindowRef } from '@x/common/browser/window.service';

@Injectable()
export class DownloadService {
  constructor(private windowRef: WindowRef) {}

  downloadFile(fileData: string, fileName: string) {
    const blob = new Blob(['\ufeff' + fileData], { type: 'text/csv;charset=utf-8;' });
    const element = this.windowRef.document.createElement('a');
    const url = URL.createObjectURL(blob);
    element.setAttribute('href', url);
    element.setAttribute('download', fileName);
    element.style.visibility = 'hidden';
    this.windowRef.document.body.appendChild(element);
    element.click();
    this.windowRef.document.body.removeChild(element);
  }
}
