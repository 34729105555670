import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DownloadService } from './download.service';
import { WindowRef } from './window.service';

@NgModule({
  imports: [CommonModule],
  providers: [WindowRef, DownloadService],
})
export class BrowserModule {}
